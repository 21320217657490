import React, {useState, useEffect} from 'react'
import avatar from '../assets/Mawingubluelogo.png'
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { API_URL, secret_key, currentYear, saveLogs } from '../General'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';
import Swal from "sweetalert2";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import Langpage from './Langpage';

const Home = () => {
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const [modal2IsOpen, setmodal2IsOpen] = useState(false);
    const [modal3IsOpen, setmodal3IsOpen] = useState(false);
    
    const [details, setDetails]=useState("")
    const [pakages, setPakages]=useState([])

    const storedData = localStorage?.getItem('user');

    const [isInputValid, setInputValid] = useState(false);

    const [selectedItem, setSelectedItem] = useState('');
    const [selectedCode, setSelectedCode] = useState('');

    const [selectedAmount, setSelectedAmount] = useState(0);
    // const [pkgAmount, setPkgAmount] = useState(0);

    const [wifipassword, setWifiPassword] = useState("");
    const [wifiName, setWifiName] = useState("");

    const [validatorMsg, setValidatorMsg] = useState("");
    const [offlineMsg, setOfflineMsg] = useState("");

    const [data, setData] = useState({
      newpass: "",
      wifi_name: wifiName
    })

    const { t } = useTranslation();

    if (storedData) {
      var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
      var logeduserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    const custno =logeduserData.Customer_no
    const [phoneNumber, setPhoneNumber] = useState(logeduserData?.phone);

    const getCustomerDetails =() =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${API_URL}/get_customer_details`,
        headers: { 
          'Content-Type': 'application/json'
        },
          data : {
            "custno": custno
          }
      };
      
      axios.request(config)
      .then((response) => {
        setDetails(response.data)

        const cleanedString = (response.data?.package_amount)?.replace(/,/g, '');
        setSelectedAmount(parseFloat(cleanedString))

        setSelectedItem(response.data?.package_code);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  
    useEffect(() => {
      getCustomerDetails();
    }, [custno])

    const handleKeyDown = (event) => {
    if (
      !(event.key >= '0' && event.key <= '9') &&
      event.key !== 'Backspace' && 
      event.key !== 'ArrowLeft' &&
      event.key !== 'ArrowRight' &&
      event.key !== 'Delete'
    ) {
      event.preventDefault();
    }
  };

    const getPackageList =() =>{
      let config = {
        method: 'get',
        maxBodyLength: Infinity, 
        url: `${API_URL}/get_package_list/${details?.address}`,
        headers: { 
          'Content-Type': 'application/json'
        }
      };
      
      axios.request(config)
      .then((response) => {
        setPakages(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
    }
  
    useEffect(() => {
      getPackageList();
    }, [details])

    const getCurrentWifiPass =() =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${API_URL}/getWifiPassword`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data: {
          ip: details?.ip_address
        }
      };
      axios.request(config)
      .then((response) => {
        if(response.data && response.data.length < 3){
          if(details?.ip_address && details?.ip_address?.startsWith("10.40")){
            setOfflineMsg("")
          }else{
            setOfflineMsg("Password change error")
          }
        }else{
          setOfflineMsg("")
          setWifiPassword(response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  
    useEffect(() => {
      if(details){
        getCurrentWifiPass();
      }
    }, [details])

    const getCurrentWifiName =() =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${API_URL}/getwifi_name`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data: {
          ip: details?.ip_address
        }
      };
      axios.request(config)
      .then((response) => {
        if(response.data && response.data.wifi_name.trim().length == ""){
          if(details?.ip_address && details?.ip_address?.startsWith("10.40")){
            setOfflineMsg("")
          }else{
            setOfflineMsg("Password change error")
          }
        }else{
          setOfflineMsg("")
          setWifiName(response.data.wifi_name)

          setData({...data, 
            wifi_name:response.data.wifi_name
          })
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
  
    useEffect(() => {
      if(details){
        getCurrentWifiName();
      }
    }, [details])

    let navigate = useNavigate();
    const openModal = () => {
      setmodalIsOpen(true);
    }
    const closeModal = () => {
      setmodalIsOpen(false);
      }
    const openModal2 = () => {
      setmodal2IsOpen(true);
    }
    const closeModal2 = () => {
      setmodal2IsOpen(false);
      setValidatorMsg("")
      setErrorMessage("")
      }
      const openModal3 = () => {
        setmodal3IsOpen(true);
      }
      const closeModal3 = () => {
        setmodal3IsOpen(false);
        setValidatorMsg("")
        setErrorMessage("")
        }
      
    const handlePhoneChange = (e) =>{
        setPhoneNumber(e.target.value)
    }
    const handlePkgChange = (e) =>{
        setSelectedAmount(e.target.value)
    }
    const handleChange = (e) =>{
      setErrorMessage("")
      const newdata = { ...data }
      newdata[e.target.id] = e.target.value
      setData(newdata)
  }

   const handleFocus = () => {
    if(data.newpass.length < 8){
      setValidatorMsg("Password should be at least 8 characters, should not have a space")
    }else{
      setValidatorMsg("")
    }
   }

   const handlekeydown = (event) => {
    if (event.code === 'Space') event.preventDefault()
   }

    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');

    const customStyles = {
        content: 
        {
            top: '14%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            border: 'none',
            marginTop: '40px'
        },
    };

    const isFormValid = () => {
      return phoneNumber != '' && selectedAmount != 0;
    };

    useEffect(() => {
      const isValid = isFormValid();
      setInputValid(isValid);
    }, [phoneNumber, selectedAmount]);
    
    const paySubscription = (e) => {
        e.preventDefault();
        setLoading(true);
        let formData = {
            "phone": phoneNumber ,
            "amount": selectedAmount,
            "account_number": custno
          };

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${API_URL}/deposit_fund`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : formData
        };
              
        axios.request(config)
        .then((response) => { 
          setLoading(false);  
          toast('Payment initiated Enter your M-PESA Pin!');
          saveLogs(logeduserData.Customer_no, logeduserData.phone,"Renew subscription submit", details?.email_address)
          // setmodalIsOpen(false);

          let updateData = {
            "CustomerNo": custno,
            "newpackage": selectedCode
          };
    
          let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${API_URL}/update_package`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : updateData
          };
                
          axios.request(config)
          .then((response) => { 
            // console.log(response)  
          })
          .catch((error) => {
            console.log(error) 
          });

          setTimeout(()=>{
            navigate(0);
          }, 4000)
        })
        .catch((error) => {
          console.log(error) 
        });
    }

    function capitalizeFirstLetter(fullname) {
      if (!fullname) return '';
    
      const nameParts = fullname.split(" ");
      const firstName = nameParts[0] || '';
      const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    
      return capitalizedFirstName;
    }
    
    const changeWifiPass = (e) =>{
      e.preventDefault();
      setLoading(true);

      let formData = {
        "ipAddress": details?.ip_address,
        "custno": logeduserData.Customer_no,
        "password": data.newpass,
        "wifi_name": data.wifi_name
      };

      if(data.newpass.length < 8){
        setErrorMessage("Password should be at least 8 characters");
        setLoading(false)
        return;
      }
      else{
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${API_URL}/change_WifiPassword`,
          headers: { 
            'Content-Type': 'application/json'
          },
          data : formData
        };
        
        axios.request(config)
        .then((response) => {
          let config = {
            method: 'post',
            maxBodyLength: Infinity, 
            url: `${API_URL}/getWifiPassword`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data: {
              ip: details?.ip_address
            }
          };
          axios.request(config)
          .then((response) => {
            setErrorMessage("")
            setLoading(false);  
            saveLogs(logeduserData.Customer_no, logeduserData.phone,"Change wifi submit", details?.email_address)
            
            if(response.data.length < 3){
              Swal.fire({
                icon: "error",
                title: "Error!",
                html: 'Your password was not updated</br> Check if your router is powered on'
              })
              .then(() => {
                navigate(0); 
              });
            }

            if(response.data.toString().replace(/\r?\n|\r/g, ' ').replace(/\s/g, '') != data.newpass.replace(/ /g, '')){
              Swal.fire({
                icon: "error",
                title: "Error!",
                html: 'Try Again </br>Check your internet connection.'
              })
              .then(() => {
                navigate(0); 
              });
            }else{  
              Swal.fire({
                icon: "success",
                title: "Success!",
                text: "Your new password is "+response.data,
              })
              .then(() => {
                navigate(0); 
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
          
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage(error?.response?.data?.error)
        });
  
      }     
    }

    const removeError = () => {
      setErrorMessage("")
    }

    const logout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate(0)
    };

    const handleInvoicesClick = () =>{
      navigate("/invoices")
    }
    const handleReceiptsClick = () =>{
      navigate("/receipts")
    }
 
    const formattedDate = moment(details?.disconnection_date).format('DD/MMM/YYYY');
    

    const handleSelectionChange = (event) => {
      const selectedDes = event.target.value;
      const selectedData = pakages.find(pk => pk.Description === selectedDes);
  
      if (selectedData) {
        setSelectedItem(selectedDes);
        setSelectedCode(event.target.options[event.target.selectedIndex].id)
        setSelectedAmount(parseFloat((selectedData.Amount)?.replace(/,/g, '')));
      }
    };

    const [pindata, setpinData] = useState({
      pin: "",
      cpin: "",
      custno: logeduserData.Customer_no,
      phone: logeduserData.phone
    })

    const handlePinChange = (e) =>{
      const newdata = { ...pindata }
      newdata[e.target.id] = e.target.value
      setpinData(newdata)
  }

  const changeMyPin = (e) => {
    e.preventDefault();
    if(pindata.pin.toString().length !== 4){
      Swal.fire({
        icon: "error",
        title: "Error!",
        html: 'The pin should have 4 digits'
      })
    }else if(pindata.pin !== pindata.cpin){
      Swal.fire({
        icon: "error",
        title: "Error!",
        html: 'The pins do not match'
      })
    }else{
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/pass_update`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : pindata
      };
      
      axios.request(config)
      .then((res) => {
        console.log(res)
        if(res.data.success == 1){
          Swal.fire({
            icon: "success",
            title: "Success!",
            html: 'Your pin has been updated'
          })
        }else{
          Swal.fire({
            icon: "error",
            title: "Error!",
            html: 'An error has occurred.Try again later'
          })
        }      
      })
      .then(() => {
        setTimeout(() => {
          navigate(0);
        }, 2000);
      })
    }
  }

  

return (
    <>
    <div className="logoutlang">
      <button onClick={logout} className="logout-button">
        {t('logout')}
      </button>
      <button className='userActions pin-button' id='changeUserPin' 
        onClick={() => {
          openModal3();
        }}> {t('change_pin')}</button>

      <Langpage />
    </div>
    <div className="self-carepage">
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-container">
            <div className="modal-title">
              <i onClick={closeModal} className="fa fa-window-close red-close-icon" aria-hidden="true"></i>
            </div>
            <div className="titlestyle">
            {t('make_payment')}
            </div>
            <div className="modal-content">
              <form onSubmit={paySubscription}>
                <div className="user-details">
                  <div className="input-box">
                    <span className="details">{t('phone_number_maessage')}</span>
                    <input type="tel" 
                      placeholder="Enter paying phone number" 
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                    />
                  </div>
                </div>
                {/* <div className="user-details">
                  <div className="input-box">
                    <span className="details">Current Package</span>
                    <input type='tel'
                      value={}
                      readOnly
                    />
                  </div>bbv
                </div> */}
                 <div className="user-details">
                  <div className="input-box">
                    <span className="details">{t('package_upgrade')}</span>
                    {pakages.some(pk => pk.Description.includes(selectedItem)) && selectedItem !=="" ? 
                    <select
                      className='selecttxt'
                        type="tel"
                        value={selectedItem}
                        onChange={handleSelectionChange }
                      >
                        {pakages.map((pk, index) =>(
                          <option key ={index} value={pk.Description} id={pk.Code} >{pk.Description.split('-')[1]}</option>

                        ))}                      
                    </select> :
                    <select
                    className='selecttxt'
                      type="tel" 
                      value={selectedItem}
                    >                        
                    <option value={selectedItem}>{selectedItem.split('-')[1]}</option>                    
                  </select>
                  
                    }
                   
                  </div>
                </div>
                <div className="user-details">
                  <div className="input-box">
                    <span className="details">{t('subscription_amount')}</span>
                    <input type='tel'
                       value={selectedAmount}
                       onChange={handlePkgChange}
                    />
                  </div>
                </div>
                
               
                <div className="button">
                  <input
                    type="submit"
                    value={loading ? t('submitting') : t('submit')}
                    disabled={loading || !isInputValid}
                  />
                </div>
              </form>
            </div>
          </div>

        </Modal>

        <Modal
          isOpen={modal2IsOpen}
          onRequestClose={closeModal2}
          style={customStyles}
          contentLabel="Example Modal"
          // id='change_WIFIPass'
        >
          <div className="modal-container">
            <div className="modal-title">
              <i onClick={closeModal2} className="fa fa-window-close red-close-icon" aria-hidden="true"></i>
            </div>
            <div className="titlestyle">
            <span className='titlestyle'>{t('manage_wifi')}</span>
            </div>
            <div className="modal-content">
              <form onSubmit={changeWifiPass}>

                <div className="user-details">
                  <div className="input-box">
                    <span className="details">{t('wifi_name')}</span>
                    <input type="text"  
                      onChange={handleChange}
                      value={data.wifi_name}
                      id='wifi_name'
                    />
                  </div>
                  <div className='passvalidator'>{offlineMsg}</div>
                </div>
                <div className="user-details">
                  <div className="input-box">
                    <span className="details">{t('current_wifi_password')}</span>
                    <input type="text" 
                      readOnly 
                      value={wifipassword}
                    />
                  </div>
                  <div className='passvalidator'>{offlineMsg}</div>
                </div>
                <div className="user-details">
                  <div className="input-box">
                    <span className="details">{t('enter_new_wifi_password')}</span>
                    <input type="text" 
                      placeholder={t('enter_new_wifi_password')} 
                      required 
                      onKeyDown ={handlekeydown}
                      onKeyUp={handleFocus}
                      onChange={handleChange}
                      id='newpass'
                    />
                  </div>
                  <div className='passvalidator'>{validatorMsg}</div>

                </div>
                                    
               
                <div className="button">
                  <input
                    type="submit"
                    value={loading ? t('submitting') : t('submit')}
                    disabled={loading}
                  />
                </div>
              </form>
            </div>
          </div>

        </Modal>

        <Modal
          isOpen={modal3IsOpen}
          onRequestClose={closeModal3}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="modal-container">
            <div className="modal-title">
              <i onClick={closeModal3} className="fa fa-window-close red-close-icon" aria-hidden="true"></i>
            </div>
            <div className="titlestyle">
            {t('change_pin')}
            </div>
            <div className="modal-content">
              <form onSubmit={changeMyPin}>
                <div className="user-details">
                  <div className="input-box">
                    <span className="details">{t('enter_pin')}</span>
                    <input type="number" 
                      placeholder={t('enter_pin')} 
                      id='pin'
                      onChange={handlePinChange}
                      onKeyDown={handleKeyDown}
                      required
                    />
                  </div>
                </div>

                <div className="user-details">
                  <div className="input-box">
                    <span className="details">{t('confirm_pin')}</span>
                    <input type="number" 
                      placeholder={t('confirm_pin')} 
                      id='cpin'
                      onChange={handlePinChange}
                      onKeyDown={handleKeyDown}
                      required
                    />
                  </div>
                </div>              
               
                <div className="button">
                  <input
                    type="submit"
                    value={loading ? t('submitting') : t('submit')}
                    disabled={loading || !isInputValid}
                  />
                </div>
              </form>
            </div>
          </div>

        </Modal>

        <div className="home-content">
        <img src={avatar} alt='' />
        <div className="text-wrapper-2">{t('welcomestaff')}, {logeduserData ? capitalizeFirstLetter(logeduserData.full_name) : "Customer"}</div> 
        <div className='usercardData'>
            <span className='homeHeadings'>{t('account_number')}:</span> {logeduserData ? logeduserData.Customer_no : ""}
            <br/>
            <span className='homeHeadings'>{t('email')}:</span> {details ? details?.email_address : ""}
            <br />
            <span className='homeHeadings'>{t('phone')}:</span> {logeduserData ? logeduserData.phone : ""}
            <br />
            <span className='homeHeadings'>{t('current_packages')}:</span> {details ? details?.package_code.split('-')[1] : ""}
            <br />
            <span className='homeHeadings'>{t('renewal_date')}:</span> {formattedDate}
            <br/>
        </div>
       
        <div className='actionbtns'>
            <button className='userActions' id='change_WIFIPass' 
            onClick={() => {
              openModal2();
              saveLogs(logeduserData.Customer_no, logeduserData.phone,"Change wifi password", details?.email_address)
            }}> {t('manage_wifi')}</button>
            <button className='userActions' 
            onClick={() => {
              openModal();
              saveLogs(logeduserData.Customer_no, logeduserData.phone,"Renew subscription", details?.email_address)
            }}
            >{t('pay_now')}</button>
        </div> 

        <div className='actionbtns'> 
            <button className='userActions' 
              onClick={() => {
                handleInvoicesClick();
                saveLogs(logeduserData.Customer_no, logeduserData.phone,"Download invoice", details?.email_address)
              }} 
            > {t('invoices')}</button>
            <button className='userActions' 
              onClick={() => {
                handleReceiptsClick();
                saveLogs(logeduserData.Customer_no, logeduserData.phone,"Download receipt", details?.email_address)
              }}
            > {t('receipts')}</button>
        </div>
                              
                
        <div className="support-call">
          {t('support')}:
          <br />
          {t('callwhatsapp')}: 011-1194-000
          <br />
          {t('email')}: support@mawingu.co
        </div>
        <div className="copyright">{t('copyright')} © {currentYear}-Mawingu</div>
        </div>
        {/* <ToastContainer 
          position="bottom-center"
          autoClose={5000} // Adjust as needed
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        /> */}
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          style={{ top: '30%', left: '50%', transform: 'translate(-50%, -50%)' }} // Center both vertically and horizontally
        />
    </div>
    </>
)
}

export default Home