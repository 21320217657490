import axios from 'axios';

export const API_URL = 'https://selfcare-backend.mawingu.co';

export const secret_key = "mawingu@2023secret"
export function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;
  
    return formattedDate;
}
export const currentYear = new Date().getFullYear();

export const LANGUAGES = [
  { label: "English", code: "en" },
  { label: "Somali", code: "so" },
];

export const saveLogs = (cust_no, phone, source, email) => {
  let data = {
    "cust_no": cust_no,
    "phone": phone,
    "source": source,
    "email": email
  };

  let config = {
    method: 'post',
    url: 'https://selfcare-backend.mawingu.co/save_newlogs',
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  axios.request(config)
  .then((response) => {
    console.log(1);
  })
  .catch((error) => {
    console.log(error);
  });

}