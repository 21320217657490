import React, {useState} from 'react'
import removal1 from '../assets/F1.png'
import avatar from '../assets/Mawingubluelogo.png'
import {Icon} from 'react-icons-kit';
import {eyeOff} from 'react-icons-kit/feather/eyeOff';
import {eye} from 'react-icons-kit/feather/eye'
import axios from 'axios';
import { API_URL, secret_key, currentYear } from '../General'
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";
import Langpage from './Langpage';

const Login = () => {
// initialize form values
const [data, setData] = useState({
    cust_no: "",
    password: ""
})
let navigate = useNavigate();

const { t } = useTranslation();

// initialize error message
const [errorMessage, setErrorMessage] = useState('');
const [loading, setLoading] = useState(false);
const [icon, setIcon] = useState(eyeOff);
const [type, setType] = useState('password');

const handleToggle = () => {
  if (type==='password'){
     setIcon(eye);
     setType('text')
  } else {
     setIcon(eyeOff)
     setType('password')
  }
}

// set change form values
const handleChange = (e) =>{
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
}
// login user
const loGin = (e) =>{
      e.preventDefault();
      setLoading(true);

      let formData = {
        "cust_no": data.cust_no,
        "password": data.password
      };
      
      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${API_URL}/login`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : formData
      };
      
      axios.request(config)
      .then((response) => {
        setErrorMessage("")
        const encryptedToken = CryptoJS.AES.encrypt(response.data.token, secret_key).toString();
        window.localStorage.setItem('token', encryptedToken);
        var encrypteduser = CryptoJS.AES.encrypt(JSON.stringify(response.data.user), secret_key).toString();
        window.localStorage.setItem('user', encrypteduser);
        setLoading(false);
        
        navigate(0); 
      })
      .catch((error) => {
        setLoading(false);
        setErrorMessage(error?.response?.data?.error)
      });

    }

    const [isChecked, setIsChecked] = useState(true);

    const handleCheckboxChange = () => {
      setIsChecked(!isChecked); // Toggle the checked state
    };

    const removeError = () => {
      setErrorMessage("")
    }

    const handleNewUserClick = () => {
      navigate("/register")
    }

    const handleForgotUserClick = () => {
      navigate("/password_reset")
    }
    const handlePaste = (e) => {
      const pastedText = (e.clipboardData || window.clipboardData).getData('text');
      setTimeout(() => {
        const formattedText = pastedText.replace(/\s+/g, '').replace(/\s/g, '').slice(0, 4);
        setData((prevData) => ({ ...prevData, password: formattedText }));
      }, 0);
    };

    const handleInput =(e) => {
      const formattedText = e.target.value.replace(/\D/g, '').replace(/\s/g, '').slice(0, 4);
      setData((prevData) => ({ ...prevData, password: formattedText }));
    };

    const handlekeydown = (event) => {
      if (event.code === 'Space') event.preventDefault()
     }
    
    return (
        <div className="self-care">
            <div className="row auth-container">
                <div className="col-6 login-content">
                  <div className='logForm rectangle'>
                  <Langpage />
                    <form onSubmit={loGin}>
                        <img src={avatar} alt='' />
                        <div className="text-wrapper-2">{t('welcome')}</div>                        
                        <div className="log-in">{t('login')}</div>

                          <label htmlFor="cust_no" className="input-label">
                            {t('enter_cust_no')}:
                          </label>
                          <input
                            type="text"
                            className="form-control formInfor"
                            placeholder={t('cust_no')}
                            id="cust_no"
                            // value={custno}
                            onChange={handleChange}
                            onFocus={removeError}
                            required
                          />
                          <label htmlFor="password" className="input-label">
                            {t('enter_pin')}:
                          </label>
                          <div className='passiniput'>
                            <input
                              type={type}
                              pattern="^[0-9]{4}$"
                              maxLength="4"
                              className="form-control formInfor"
                              placeholder={t('pin')}
                              id="password"
                              onChange={handleChange}
                              onFocus={removeError}
                              onKeyDown={handlekeydown}
                              onPaste={(e)=> handlePaste(e)}
                              onInput={(e)=> handleInput(e)}
                              required
                            />
                            <span className="flex justify-around items-center" onClick={handleToggle}>
                              <Icon className="showpassicon" icon={icon} size={20}/>
                            </span>
                          </div>
                          
                          
                        <div className="forgot-password">
                          <div className="overlap-group">
                            <input 
                              type='checkbox' 
                              className="checkbox-instance" 
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            <div className="remember-me">
                              <div className="div">{t('remember_me')}</div>
                            </div>
                          </div>
                          <div className=" forgotpass" onClick={() => handleForgotUserClick()}>{t('resend_pin')}</div>
                        </div>
                        <input
                          type="submit"
                          className="Auth-btn"
                          value={loading ? t('logging') : t('login')}
                          disabled={loading}
                        />
                        {errorMessage && <div className="alert alert-danger Errorbox">{errorMessage}</div>}
                        {/* <button className='formInfor newuser' onClick={() => handleNewUserClick()}>{t('new_user')}
                        </button> */}
                    </form>

                    <div className="support-call">
                      {t('support')}:
                      <br />
                      {t('callwhatsapp')}: 011-1194-000
                      <br />
                      {t('email')}: support@mawingu.co
                    </div>
                    <div className="copyright">{t('copyright')} © {currentYear}-Mawingu</div>
                    </div>
                </div>
                <div className="col-6 Loginpageimg">
                    <img src={removal1} alt='' />
                </div>
            </div>
        </div>
    )
}

export default Login