// import React from 'react'
// import { LANGUAGES } from '../General'
// import { useTranslation } from "react-i18next";

// const Langpage = () => {
//   const { i18n } = useTranslation();

//   const onChangeLang = (e, code) => { // Modify onChangeLang to accept the event object and the code
//       const lang_code = code; // Extract the code from the argument
//       i18n.changeLanguage(lang_code);
//   };
// const activeLanguage =i18n.language;
//   return (
//       <div className='langselect'>
//           <div className='flex-container'>
//               {LANGUAGES.map(({ code, label }) => (
//                   <button key={code} onClick={(e) => onChangeLang(e, code)}className={activeLanguage === code ? 'active' : ''}> {/* Pass the event object and the code to onChangeLang */}
//                       {label}
//                   </button>
//               ))}
//           </div>
//       </div>
//   );
// };

// export default Langpage



// import React, { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { setLanguage } from '../features/reducers';
// import { LANGUAGES } from '../General';
// import { useTranslation } from 'react-i18next';

// const Langpage = () => {
//   const dispatch = useDispatch();
//   const { i18n } = useTranslation();
//   const language = useSelector(state => state.language.language);

//   // Update i18n language when Redux language changes
//   useEffect(() => {
//     console.log(language)
//     i18n.changeLanguage(language);
//   }, [i18n, language]);

//   const onChangeLanguage = (e) => {
//     const selectedLanguage = e.target.value;
//     dispatch(setLanguage(selectedLanguage));
//     console.log(selectedLanguage)
//   };

//   return (
//     <div className='langselect'>
//         <select defaultValue={language} onChange={onChangeLanguage}>
//             {LANGUAGES.map(({ code, label }) => (
//             <option key={code} value={code}>
//                 {label}
//             </option>
//             ))}
//         </select>
//     </div>
//   );
// };

// export default Langpage;


import React, { useEffect, useState } from 'react';
import { API_URL, secret_key, LANGUAGES, saveLogs } from '../General';
import { useTranslation } from 'react-i18next';
import CryptoJS from 'crypto-js';
import axios from 'axios';

const Langpage = () => {
  const { i18n } = useTranslation();
  const [activeLanguage, setActiveLanguage] = useState(
    localStorage.getItem('selectedLanguage') || 'en'
  );

  const storedData = localStorage?.getItem('user');
  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var logeduserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  const [details, setDetails]=useState("");
  const custno = logeduserData?.Customer_no

  const getCustomerDetails =() =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${API_URL}/get_customer_details`,
        headers: { 
          'Content-Type': 'application/json'
        },
          data : {
            "custno": custno
          }
      };
      
      axios.request(config)
      .then((response) => {
        setDetails(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
    }
  
  useEffect(() => {
    getCustomerDetails();
  }, [custno])

  useEffect(() => {
    const langFromStorage = localStorage.getItem('selectedLanguage');
    if (langFromStorage) {
      setActiveLanguage(langFromStorage);
      i18n.changeLanguage(langFromStorage);
    }
  }, [i18n]);

  const onChangeLang = (code) => {
    setActiveLanguage(code);
    localStorage.setItem('selectedLanguage', code);
    i18n.changeLanguage(code);
  };

  return (
    <div className='langselect'>
      <div className='flex-container'>
        {LANGUAGES.map(({ code, label }) => (
          <button
            key={code}
            onClick={() => {
              onChangeLang(code);

              if(logeduserData?.Customer_no){
                saveLogs(logeduserData.Customer_no, logeduserData.phone,label, details?.email_address);
              }
            }}
            className={activeLanguage === code ? 'active' : ''}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Langpage;
