import React, {useState} from 'react'
import removal1 from '../assets/F1.png'
import avatar from '../assets/Mawingubluelogo.png'

import axios from 'axios';
import { API_URL, currentYear } from '../General'
import { useNavigate } from 'react-router-dom'
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Langpage from './Langpage';


const Register = () => {
// initialize form values
const [data, setData] = useState({
    cust_no:"",
    phone: "",

})
let navigate = useNavigate();
const { t } = useTranslation();

const handleGoBack = () => {
  navigate(-1); // Go back one step in the navigation history
};

// initialize error message
const [errorMessage, setErrorMessage] = useState('');
const [loading, setLoading] = useState(false);

// set change form values
const handleChange = (e) =>{
    const newdata = { ...data }
    newdata[e.target.id] = e.target.value
    setData(newdata)
}
// login user
const signup = (e) =>{
  e.preventDefault();
    setLoading(true);

    let formData = {
      "custno": data.cust_no,
      "phone": data.phone,

    };
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_URL}/reset_password`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : formData
    };
    
    axios.request(config)
    .then((response) => {
      setErrorMessage("")
      setLoading(false);       
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "New User successfully been created",
      })
      .then(() => {
        navigate("/login"); 
      });
      
    })
    .catch((error) => {
      setLoading(false);
      setErrorMessage(error?.response?.data?.error)
    });

    }

    const removeError = () => {
      setErrorMessage("")
    }
    
    return (
        <div className="self-care">
            <div className="row auth-container">
                <div className="col-6 login-content">
                  <div className='logForm rectangle'>
                  <Langpage />
                    <form onSubmit={signup}>
                        <img src={avatar} alt='' />
                        <div className="text-wrapper-2">{t('welcome')}</div>                        
                        <div className="log-in" style={{textTransform:'capitalize'}}>{t('new_user')}</div>
                        <label htmlFor="cust_no" className="input-label">
                          {t('enter_cust_no')}:
                        </label>
                          <input
                            type="text"
                            className="form-control formInfor"
                            placeholder={t('cust_no')}
                            id="cust_no"
                            onChange={handleChange}
                            onFocus={removeError}
                            required
                          /> 
                          <label htmlFor="phone" className="input-label">{t('enter_phone_number')}:</label>                         
                        <input
                            type="tel"
                            className="form-control formInfor"
                            placeholder={t('phone_number')}
                            id="phone"
                            onChange={handleChange}
                            onFocus={removeError}
                            required
                          />
                         
                      
                        <input
                          type="submit"
                          className="Auth-btn"
                          value={loading ? t('submitting') : t('new_user')}
                          disabled={loading}
                        />
                        {errorMessage && <div className="alert alert-danger Errorbox">{errorMessage}</div>}

                        <button className='formInfor newuser' onClick={() => handleGoBack()}>{t('login')}</button>


                    </form>
                    <div className="support-call">
                      {t('support')}:
                      <br />
                      {t('callwhatsapp')}: 011-1194-000
                      <br />
                      {t('email')}: info@mawingu.co
                    </div>
                    <div className="copyright">{t('copyright')} © {currentYear}-Mawingu</div>
                    </div>
                </div>
                <div className="col-6 Loginpageimg">
                    <img src={removal1} alt='' />
                </div>
            </div>
        </div>
    )
}

export default Register