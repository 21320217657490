import React, {useEffect, useState} from 'react'
import avatar from '../assets/Mawingubluelogo.png'
import { API_URL, secret_key, currentYear, saveLogs} from '../General';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import {useNavigate} from "react-router-dom"
import {Document, Page, pdfjs} from "react-pdf"
import { useTranslation } from "react-i18next";
import Langpage from './Langpage';

function Invoices() {
  const storedData = localStorage?.getItem('user');
  const [invoices, setInvoices] =useState([])
  const [pdfData, setPdfData] =useState([])
  const [numPages, setNumPages] = useState(null);
  const [details, setDetails]=useState("");

  if (storedData) {
    var bytes = CryptoJS.AES.decrypt(storedData, secret_key);
    var logeduserData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate(-1); // Go back one step in the navigation history
  };

  const custno = logeduserData.Customer_no
  const getCustomerDetails =() =>{
      let config = {
        method: 'post',
        maxBodyLength: Infinity, 
        url: `${API_URL}/get_customer_details`,
        headers: { 
          'Content-Type': 'application/json'
        },
          data : {
            "custno": custno
          }
      };
      
      axios.request(config)
      .then((response) => {
        setDetails(response.data)
      })
      .catch((error) => {
        console.log(error);
      });
    }
  
    useEffect(() => {
      getCustomerDetails();
    }, [custno])


  const getCustomerInvoice =() =>{
    let config = {
      method: 'post',
      maxBodyLength: Infinity, 
      url: `${API_URL}/get_cust_invoices`,
      headers: { 
        'Content-Type': 'application/json'
      },
        data : {
          "custno": custno
        }
    };
    
    axios.request(config)
    .then((response) => {
      const sortedData = response.data.sort((a, b) => {
        const dateA = new Date(a.inv_date);
        const dateB = new Date(b.inv_date);
      
        // Compare the dates to sort in descending order (newest first).
        return dateB - dateA;
      });
      
      setInvoices(sortedData); 
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    getCustomerInvoice();
  }, [custno])

  const getInvoicePDF = (inv_no) =>{
    let config = {
      method: 'post',
      maxBodyLength: Infinity, 
      url: `${API_URL}/get_customer_invoices`,
      headers: { 
        'Content-Type': 'application/json'
      },
        data : {
          
            "DocumentNo":inv_no,
            "document_type":"INV"
        
        }
    };

    console.log(inv_no)
    
    axios.request(config)
    .then((response) => {
      const byteCharacters = atob(response.data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      setPdfData(blobUrl);
      
      // Preview the PDF
      window.open(blobUrl, '_blank');
    })
    .catch((error) => {
      console.log(error);
    });
}
  
  return (
    <div className="overlap-group1">
      
      <div className="logoutlang">
          <button onClick={handleGoBack} className="back-button" >
            &lt;&lt; {t('back')}
          </button>
          <Langpage />
      </div>
      <img src={avatar} alt='' />
      <div className="div23">
      <div className="text1-wrapper">{t('invoices')}</div>    
      <div className="wraprshead">
        
        <div className="text1-wrapper-3">{t('invoice_number')}</div>
        <div className="text1-wrapper-3">{t('date')}</div>
        <div className="text1-wrapper-3">{t('amount')}</div>
        <div className="text1-wrapper-3">{t('action')}</div>
      </div>
      
      {invoices.map((invoice)=>{
        return(
        <div className="wraprs" key={invoice.inv_number}>
        <div className="list-item">{invoice.inv_number}</div>
        <div className="list-item">{invoice.inv_date}</div>
        <div className="list-item">{invoice.inv_amount}</div>
        <div className="list-item ">{invoice.length > 0}
        <a onClick={()=> {
          getInvoicePDF(invoice.inv_number)
          saveLogs(logeduserData.Customer_no, logeduserData.phone,"Download invoice view", details?.email_address)
        }} className="btn btn-sm btn-info">{t('view')}</a></div>
        
      </div>)
      })}
      </div>
      <div className="copyright">{t('copyright')} © {currentYear}-Mawingu</div>
    </div>
  )
}

export default Invoices