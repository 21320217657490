import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./i18n";
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux'

import reportWebVitals from './reportWebVitals';

import languageReducer from './features/reducers';

const store = configureStore({
  reducer: {
    language : languageReducer
  },
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <App />
    </Provider>
);
reportWebVitals();
